import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { ErrorComponent } from '../components/Error'
import Seo from '../components/seo'
import Navigation from '../components/NavBar'
import { BlogBigSnippet } from '../components/BlogBigSnippet'
import { WritingPageQuery } from '../generated/graphql'
import { WindowLocation } from '@reach/router'
// @ts-ignore
import BgImage from '../blog-bg.png'

export const WritingPageContainer = ({
  data,
  location,
  locale = 'en-US',
}: {
  data: WritingPageQuery
  location: WindowLocation
  locale?: string
}) => {
  if (!data.blogs) {
    return <ErrorComponent message="Unable to load Page" />
  }
  return (
    <div
      className="h-screen relative bg-[#f5f5f5] bg-cover"
      style={{ backgroundImage: `url(${BgImage})` }}
    >
      <Seo title={`Puzzle Ventures - Writing`} />
      <main className="mx-auto overflow-hidden h-full relative flex flex-col container max-w-6xl">
        <Navigation location={location} dark locale={locale} />
        <div className="flex overflow-y-auto flex-col flex-auto pb-8 w-full pt-16 px-6">
          {data.blogs.nodes.map((el) => (
            <Link to={`${el.slug}`} className="no-underline">
              <BlogBigSnippet data={el} />
            </Link>
          ))}
        </div>
      </main>
    </div>
  )
}
