import React from 'react'
import { WritingPageQuery } from '../generated/graphql'

export const BlogBigSnippet = ({
  data,
}: {
  data: WritingPageQuery['blogs']['nodes'][0]
}) => {
  return (
    <div className="max-w-2xl pb-14 mb-14 border-b border-b-gray-400 text-bg">
      <h2>{data.title}</h2>
      <div className="flex mb-4">
        <p>BY {data.author?.name}</p>
        <p className="ml-8">
          {data.publishDate} |{' '}
          {data.readingTime ? `${data.readingTime} min` : ''}
        </p>
      </div>
      <p>{data.body?.childMarkdownRemark?.excerpt}</p>
    </div>
  )
}
